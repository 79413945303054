<template>
  <div class="editManagerWrap">
    <el-form
      :model="managerForm"
      status-icon
      ref="managerForm"
      label-width="100px"
      class="managerForm"
      :rules="managerFormRules"
    >
      <el-form-item
        v-for="f in eidtableFormItems"
        :key="f.prop"
        :label="f.label"
        :prop="f.prop"
      >
        <el-select
          v-model="managerForm[f.prop]"
          placeholder="请选择"
          v-if="f.prop == 'role_ids'"
          multiple
        >
          <el-option
            v-for="item in roles"
            :key="item.id"
            :label="item.role_name"
            :value="item.id"
          />
        </el-select>
        <el-input v-model="managerForm[f.prop]" v-else></el-input>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="submitForm()">提交</el-button>
        <el-button @click="$router.push('/userManage/managers')"
          >取消</el-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      managerForm: {
        cn_name: "",
        phone: "",
        login_name: "",
        role_ids: [],
      },
      eidtableFormItems: [
        {
          prop: "cn_name",
          label: "名称",
        },
        {
          prop: "phone",
          label: "电话",
        },
        {
          prop: "login_name",
          label: "账号",
        },
        {
          prop: "role_ids",
          label: "角色",
        },
      ],
      managerFormRules: {
        cn_name: [{ required: true, message: `请输入名称`, trigger: "blur" }],
        phone: [
          { required: true, message: `请输入手机号`, trigger: "blur" },
          {
            validator: this.checkPhoneNumber,
            message: "请输入正确的手机号",
            trigger: "blur",
          },
        ],
        login_name: [
          { required: true, message: `请输入账号`, trigger: "blur" },
        ],
        role_ids: [{ required: true, message: `请选择角色`, trigger: "blur" }],
      },
      roles: [],
    };
  },
  mounted() {
    this.getManagerInfo();
    this.getRolesList();
  },
  methods: {
    checkPhoneNumber(rule, v, callback) {
      const reg = /^1(3\d|4[5-9]|5[0-35-9]|6[2567]|7[0-8]|8\d|9[0-35-9])\d{8}$/;
      if (reg.test(v)) {
        callback();
      } else {
        callback(new Error("请输入正确手机号"));
      }
    },
    getRolesList() {
      this.$request.queryRoles({ page_num: 1, page_size: 999 }).then((res) => {
        if (res.status != "success") {
          this.$message.error(res.msg);
          return;
        }
        this.roles = res?.data?.result || [];
      });
    },
    getManagerInfo() {
      const { id } = this.$route.query;
      if (!id) return;
      this.$request.getManagerInfo({ id }).then((res) => {
        if (res.status != "success") {
          this.$message.error(res.msg);
          return;
        }
        this.eidtableFormItems.forEach((item) => {
          this.managerForm[item.prop] = res.data[item.prop];
        });
      });
    },
    submitForm() {
      this.$refs.managerForm.validate((valid) => {
        if (valid) {
          const { id } = this.$route.query;
          const params = {
            ...this.managerForm,
            password: "1234567",
            id,
          };
          if (id) {
            delete params.password;
          }
          this.$request.addOrUpdateManager(params).then((res) => {
            if (res.status != "success") {
              this.$message.error(res.msg);
              return;
            }
            this.$router.push("/userManage/managers");
          });
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style lang='scss'>
.managerForm {
  background-color: #fff;
  padding: 24px 30% 24px 24px;
  .el-form-item__label {
    width: 30% !important;
  }
  .el-form-item__content {
    margin-left: 30% !important;
    .el-select {
      display: block;
    }
  }
}
</style>